import React from "react";
import Header from "./Header/Header";
import "./Hero.css";
import hero_image from "/Users/a.badoni/Desktop/gym/Fit/src/assets/hero_image_back.png";
import hero_image_back from "/Users/a.badoni/Desktop/gym/Fit/src/assets/hero_image.png";
import Heart from "/Users/a.badoni/Desktop/gym/Fit/src/assets/heart.png";
import Calories from "/Users/a.badoni/Desktop/gym/Fit/src/assets/calories.png";
import {motion} from "framer-motion";


const Hero = () => {

  const transition = {type: 'spring', duration : 3}
  return (
    <div className="hero">
      <div className="left-h">
        <Header />
        {/* the best ad */}
        <div className="the-best-ad">
          <div>
          </div>
          <span>The best fitness club in the town</span>
        </div>
        {/* hero heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span>Your</span>
          </div>
          <div></div>
          <span>Ideal Body</span>
          <div className="span">
            <span>
              We can help yo build and shape your ideal body and live your life
              to the fullest.
            </span>
          </div>
        </div>

        {/* buttons */}
        <div className="figures">
          <div>
            <span>+140 </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>+978 </span>
            <span>Members Joined</span>
          </div>
          <div>
            <span>+50 </span>
            <span>Fitness Programs</span>
          </div>
        </div>

        {/* Hero buttons */}
        <div className="hero-buttons">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">Join Now</button>
        
        <div className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>116 BPM</span>
        </div>

         {/* hero imgages */}
        <img src={hero_image_back} className="hero-image" alt="" />
        <img src={hero_image} className="hero_image_back" alt="" />

           {/* calories */}
      <div className="calories">
          <img src={Calories} alt="" />
          <div><span>Calories Burnt</span><span>229 Kcal</span></div>
        </div>

      </div>
    </div>
  );
};

export default Hero;
